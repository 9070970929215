































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {tenantStoreGetter} from '@/stores/tenant.store';
import {userStoreActions, userStoreGetter} from '@/stores/user.store';
import {PermissionLevels, PermissionObjects} from '@/misc/Enums/permission.enum';
import UserRole from '@/models/user-attributes/UserRole';
import Tenant from '@/models/Tenant';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import RJAutocomplete from '@/components/shared/custom-vuetify/RJAutocomplete.vue';

const TenantStore = namespace('tenant');
const UserStore = namespace('user');

@Component({
  components: {
    RJAutocomplete,
    RJSelect,
  },
})
export default class RoleDashboardComponent extends Vue {

  @UserStore.Action(userStoreActions.LOAD_USER_ROLES_ACTION)
  private loadRolesAction!: (payload: {tenantId: string, relations?: string[]}) => any;
  @UserStore.Action(userStoreActions.CREATE_USER_ROLE_ACTION)
  private createRoleAction!: (role: UserRole) => any;
  @UserStore.Action(userStoreActions.DELETE_USER_ROLE_ACTION)
  private deleteRoleAction!: (id: string) => any;
  @UserStore.Action(userStoreActions.UPDATE_USER_ROLE_ACTION)
  private updateRoleAction!: (role: UserRole) => UserRole;
  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private tenant!: Tenant;
  @UserStore.Getter(userStoreGetter.ROLES)
  private roles!: UserRole[];

  private showCreateDialog = false;
  private showDeleteDialog = false;
  private accessLevels = PermissionLevels;
  private permissionTypes = PermissionObjects;
  private newRoleName = '';
  private selectedRole: UserRole = new UserRole();
  private chosenRole: UserRole = new UserRole();

  private selectValues(name: string, level: string): Array<{ text: string, value: string | undefined }> {
    // create is special, there is no OWN appendix
    if (level === 'CREATE') {
      return [
        {
          text:  '-',
          value: undefined,
        }, {
          text: this.$t('ROLE_MANAGE.ALL').toString(),
          value: `${name}_${level}`,
        },
      ];
    } else {
      return [
        {
          text: '-',
          value: undefined,
        },
        {
          text: this.$t('ROLE_MANAGE.OWN').toString(),
          value: `${name}_${level}_OWN`,
        },
        {
          text: this.$t('ROLE_MANAGE.ALL').toString(),
          value: `${name}_${level}`,
        },
      ];
    }
  }

  @Watch('chosenRole')
  private updateSelectedRole() {
    this.selectedRole = this.chosenRole.copy() as UserRole;
    this.selectedRole.permissions = [...this.chosenRole.permissions];
  }

  private get isRoleChanged() {
    return JSON.stringify(this.chosenRole) === JSON.stringify(this.selectedRole);
  }

  private get hasName() {
    return this.newRoleName?.length === 0;
  }
  private showDialog() {
    this.showCreateDialog = true;
    this.newRoleName = '';
  }

  private created() {
    this.loadRoles();
  }

  private async loadRoles() {
      await this.loadRolesAction({tenantId: this.$route.params.tenantId});
      this.chosenRole = this.roles[0];
  }

  private async onConfirmChanges() {
    try {
      delete this.selectedRole.users;
      this.chosenRole = await this.updateRoleAction(this.selectedRole);
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.EDIT.SUCCESS');
    } catch (error) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.EDIT.ERROR');
    }
  }

  private async deleteRole() {
    try {
      await this.deleteRoleAction(this.selectedRole.id);
      this.showDeleteDialog = false;
      this.chosenRole = this.roles[0];
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.DELETE.SUCCESS');
    } catch (error) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.DELETE.ERROR');
    }
  }

  private async submitNewRole() {
    const newRole: UserRole = new UserRole();
    newRole.name = this.newRoleName;
    newRole.draft = false;
    newRole.global = false;
    newRole.tenantId = this.tenant.id;
    try {
      this.chosenRole = await this.createRoleAction(newRole);
      this.showCreateDialog = false;
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.CREATE.SUCCESS');
    } catch (error) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.CREATE.ERROR');
    }
  }

  private boo(e: any) {
    console.log('Here', e, this.chosenRole, this.selectedRole);
  }
}
